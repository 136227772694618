<template>
    <article class="access_qr">
        <loading
            :active.sync="isLoading"
            :can-cancel="false"
            color="#1ca9f6"
            :is-full-page="true"/>
        <div class="access_qr__content" v-if="loaded">
          <div class="access_qr__header">
            <img
              :src="company ? company.url : ''"
              class="access_qr__header--logo"
            >
            <img
              src="https://fusepongsolutions.s3.us-west-2.amazonaws.com/email_assets/logo-fa.png"
              class="access_qr__header--logo"
            >
          </div>


          <div class="access_qr__location">
            Ingresa a
            <p class="access_qr__location--name">
              {{ location_name }}
            </p>
            con este código:
          </div>

          <div
            class="access_qr__time"
            v-if="(new Date( params_end_server * 1000) < new Date() && invitation.invitation_state !== 'USED') || (new Date( params_end_server * 1000) < new Date() && invitation.invitation_state == 'COMPLETED') || !loaded"
            style="width: 96% !important"
          >
            Este código ya no está disponible
          </div>

          <div class="access_qr__time" v-else-if="expired_invitation && invitation.invitation_state !== 'USED'" style="width: 96% !important">
            Este código aún no esta disponible
          </div>

          <div class="access_qr__time" v-else>
            Código temporal.<br>
            <b>Cambiará en <span class="access_qr__time--secunds">{{time_qr}}</span> segundos</b>
          </div>

          <div class="access_qr__mask" v-if="(expired_invitation )
          || (invitation.voucher_type !== 'Invitacion' && invitation.invitation_state == 'COMPLETED')
          || !loaded" >
            <img src="@/assets/img/qr-disabled.png">
          </div>

          <div v-else>
            <div class="access_qr__code">
              <vue-qrcode v-if="qr" :value="code" :options="{ width: 160 }"></vue-qrcode>
              <vue-qrcode v-if="qr" :value="code" :options="{ width: 160 }"></vue-qrcode>
            </div>
            <div class="access_qr__code">
              <vue-qrcode v-if="qr" :value="code" :options="{ width: 160 }"></vue-qrcode>
              <vue-qrcode v-if="qr" :value="code" :options="{ width: 160 }"></vue-qrcode>
            </div>
            <div class="access_qr__code">
              <vue-qrcode v-if="qr" :value="code" :options="{ width: 160 }"></vue-qrcode>
              <vue-qrcode v-if="qr" :value="code" :options="{ width: 160 }"></vue-qrcode>
            </div>
          </div>
          <!-- <div v-else-if="embed_qr && location_type == 'Llave Permanente'">
            <div class="access_qr__code">
              <img style="width:160px; height:160px" :src="invitation.image_qr.url"></img>
              <img style="width:160px; height:160px" :src="invitation.image_qr.url"></img>
            </div>
            <div class="access_qr__code">
              <img style="width:160px; height:160px" :src="invitation.image_qr.url"></img>
              <img style="width:160px; height:160px" :src="invitation.image_qr.url"></img>
            </div>
            <div class="access_qr__code">
              <img style="width:160px; height:160px" :src="invitation.image_qr.url"></img>
              <img style="width:160px; height:160px" :src="invitation.image_qr.url"></img>
            </div>
          </div> -->

          <div class="access_qr__invitation_dates">
              <p class="access_qr__invitation_dates--title">Fecha y hora de entrada:</p>
              <div class="access_qr__start_time" style="margin-bottom: 34px;">
               {{ new Date( params_begin_server * 1000) | moment("LL")}} | {{ new Date( params_begin_server * 1000) | moment("h:mm a")}}
              </div>

              <p class="access_qr__invitation_dates--title">Válido hasta:</p>
              <div class="access_qr__start_time" style="border: 3px solid #FF6300;">
                {{ new Date( params_end_server * 1000) | moment("LL")}} | {{ new Date( params_end_server * 1000) | moment("h:mm a")}}
              </div>
          </div>
        </div>

        <div class="faccess_info_card">
          <div class="faccess_info_card__header">
            <img
                src="https://fusepongsolutions.s3.us-west-2.amazonaws.com/email_assets/fuseaccess_more.png"
            >
          </div>
          <div class="faccess_info_card__body">
            <p class="faccess_info_card__body--contact_us">Contáctanos</p>
            <a href="mailto:comercial@fusepong.com" class="faccess_info_card__body--email">comercial@fusepong.com</a>
            <a href="https://fuseaccess.com" class="faccess_info_card__body--website">Visita nuestra web</a>
          </div>
        </div>

        <div class="access_qr__footer">
          <img src="@/assets/img/faccess_logo.svg">
        </div>


        <!--<div class="code_qr" v-if="expired_invitation">
          <p style="text-align: center; font-size: 32px; margin-bottom: 20px !important">Lo sentimos, la invitación no es válida en este momento</p>
        </div>
        <div class="code_qr" v-else>
            <div class="code_qr__container">
                <h1 class="code_qr__container--title">Hola, bienvenido a {{getCompany().company_name}}</h1>
                <p class="code_qr__container--subtitle">
                    Puedes ingresar a tu ubicación <br>
                    con éste código
                </p>
                <div class="code_qr__container--qr">
                    <vue-qrcode v-if="qr" :value="code" :options="{ width: 200 }"></vue-qrcode>
                </div>
            </div>
        </div>-->
        <!-- <app-footer></app-footer> -->
    </article>

</template>
<script>
  import VueQrcode from '@chenfengyuan/vue-qrcode';
     // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components:{
      'vue-qrcode': VueQrcode,
      Loading

    },
    data(){
      return{
        expired_invitation: false,
        generate_qr: null,
        qr: true,
        flag: 0,
        code: null,
        params_user_id: null,
        params_time_send: null,
        params_device_id: null,
        params_begin_server: null,
        params_end_server: null,
        params_user_id_encrypt: null,
        params_invited_client_id: null,
        params_location_name: null,
        qrcodes: [],
        expired_previous_entry: false,
        time_qr: '',
        initial_time: '',
        invitation: [],
        company: [],
        location_name: '',
        loaded: false,
        booking_office: '',
        embed_qr:false,
        location_type:'',
        isLoading: false,
        validate_entry:''
      }
    },
    mounted() {
      try{
        let vm = this;
        let params = this.$router.app._route.query
        console.log("<<<<<<<<<<<<<<<<<<<< Router");
        console.log(this.$router);
        console.log("<<<<<<<<<<<<<<<<<<<< Params!!!");
        console.log(params);
        if (JSON.stringify(params)!=='{}' && params.device_id !== '' && params.invited_client_id !== '') {
          this.params_device_id = params.device_id
          this.params_user_id_encrypt = params.user_id_encrypt
          this.params_invited_client_id = params.invited_client_id
          this.getInvitation()
          this.validatePreviousEntry()
          window.setInterval(function(){
            console.log('****del interval****')
            clearInterval(vm.generate_qr)
            vm.getInvitation()
            vm.validatePreviousEntry()
          } , 60000)
          // this.startQR()
          // this.isLoading = true
          window.setInterval(function(){
            let time_send_qr = Math.floor(new Date().getTime())
            let format_time_send_qr = Math.floor(time_send_qr/1000)
            let format_initial_time = Math.floor(vm.initial_time/1000)
            if(format_initial_time < format_time_send_qr){
              vm.time_qr = 9 - (format_time_send_qr - format_initial_time);
            }else{
              vm.time_qr = 9;
            }
          }, 1000)
        }
        document.title = 'Invitación de Acceso';
      }catch(e){
          console.log('CodeQr not params ', e);
      }
    },
    destroy(){
        clearInterval(vm.generate_qr)
    },
    methods: {
       startQR(){
        console.log("=== start ===")
        var vm = this;
        clearInterval()
        // vm.validatePreviousEntry()
        vm.makeQRCode()
        vm.generate_qr = window.setInterval(function(){
          vm.qr = false;
          vm.makeQRCode() /* : ''; */
          // vm.validatePreviousEntry()
          // vm.getInvitation()
        }, 9000)
      },
      validatePreviousEntry(){
        try {
          this.$http.post('app/bookings/invited_client_last_access', { invited_client_id: this.params_invited_client_id},)
          .then(function(response){
              this.expired_previous_entry = response.body;
              console.log('this.expired_previous_entry', this.expired_previous_entry)
          }, function(response){
              console.log(response.body.data)
          })
        } catch (e) {
          console.log(e.message)
        }
      },
      getInvitation(){
        console.log("<<<<<<<<<<<<<<<<<<<<<<< Get Invitation");
        console.log(this.$http);
        this.isLoading = true
        try {
          this.$http.get('get_invited_clients_by_id?invited_client_id='+this.params_invited_client_id)
          .then(function(response){
            console.log('response all',response);
            this.invitation = response.body.invitation;
            this.company = response.body.company;
            this.location_name = response.body.location;
            this.invitation = response.body.invitation;
            this.company = response.body.company;
            this.location_name = response.body.location;
            this.params_user_id = this.invitation.user_id;
            this.params_begin_server = Math.floor(new Date(this.invitation.start_time).getTime()/1000)
            this.params_end_server = Math.floor(new Date(this.invitation.end_time).getTime()/1000)
            this.loaded = true;
            this.booking_office = response.body.booking_office;
            this.embed_qr =  response.body.embed_qr;
            this.location_type = response.body.location_type
            console.log('embed_qr', this.embed_qr)
            console.log('booking_office', response.body.booking_office)
            this.startQR()
            this.isLoading = false
            // this.makeQRCode()
          }, function(response){
            console.log(response.body.data)
          })
        } catch (e) {
          console.log(e.message)
        }
      },
      makeQRCode(){
        console.log('makeQRCode' )
        this.qr = true;

        // var time_send = new Date().getTime() - this.params_time_send;
        let time_send = Math.floor(new Date().getTime())
        // let format_time_send = Math.floor(time_send/1000)
        let time = new Date().getTime().toLocaleString("en-US", {timeZone: "America/Bogota"})
        let format_time_send = parseInt(time.replace(/,/g, '')/1000)
        let vm = this;
        vm.initial_time = time_send;

        // console.log('time_send**',time_send)
        console.log('format_time_send**',format_time_send)
        console.log('vm.params_begin_server**',vm.params_begin_server)
        console.log('vm.params_end_server**',vm.params_end_server)
        console.log('vm.invitation**',vm.invitation)
        // console.log('timezone',Math.floor(new Date().getTime().toLocaleString("en-US", {timeZone: "America/Bogota"})) )

        if ((format_time_send >= vm.params_begin_server && format_time_send <= vm.params_end_server) && 
        !(vm.invitation.voucher_type !== 'Invitacion' && vm.invitation.invitation_state === 'COMPLETED') &&
        !(vm.invitation.voucher_type === 'Invitacion' && vm.invitation.invitation_state === 'CANCELLED') ||
         (this.expired_previous_entry)){

          let params = this.$router.app._route.query
          if(params.invited_client_id){
            // if(vm.invitation && vm.invitation.consecutive){
            //   console.log('QR tipo1')
            //     vm.code = "<"+vm.encrypt(vm.params_user_id+"//"+time_send+"//"+vm.params_device_id).toString()
            //     +"."+vm.params_user_id_encrypt+"."+vm.params_begin_server+"."+vm.params_end_server+"."+vm.params_invited_client_id+
            //     ">";
            // }
            if(vm.invitation){
              if(params.hasOwnProperty("enable_indexes")){
                let index=params.user_id_encrypt;
                let start_end = "AA";
                let weekdays = ["F0", "FA", "FB", "FC", "FD", "FE", "FF"];
                let weekday = weekdays[(new Date()).getDay()-1];
                let current_time = Date.now();
                let qr_type="4";// Invitacion Multiples usos
                let code = start_end+qr_type+index+weekday+current_time+start_end;
                vm.code=code
              }else if(vm.invitation.ticket_type == 3){
                  console.log('QR tipo3')
                  // vm.code = "<"+"3"+"."+vm.encrypt(`${vm.invitation.user_id}`)+"."+vm.encrypt(vm.invitation.rut)+"."+vm.invitation.consecutive+"."+time_send+">";
                  vm.code = "<"+"3"+"."+vm.encrypt(`${vm.booking_office}`)+"."+vm.encrypt(vm.invitation.rut)+"."+vm.invitation.consecutive+"."+time_send+">";
              }else{
                  console.log('QR tipo4')
                  vm.code = "<"+vm.encrypt(vm.params_user_id+"//"+time_send+"//"+vm.params_device_id).toString()
                  +"."+vm.params_user_id_encrypt+"."+vm.params_begin_server+"."+vm.params_end_server+"."+vm.params_invited_client_id+">";
              }
            }

          }
          console.log("Code => ", vm.code);
          vm.expired_invitation = false
          console.log("Not expired invitation");
        } else {
          vm.expired_invitation = true
          console.log("Expired invitation");
        }
      },
    }
  }
</script>

<style scoped>
  .code_qr{
    margin: 0px;
  }
</style>
